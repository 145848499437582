import {
  installKarrotBridgeDriver,
  makeKarrotBridge,
} from "@daangn/karrotbridge";
import React, { createContext, useContext } from "react";

const { driver } = installKarrotBridgeDriver();
const bridge = makeKarrotBridge({ driver });

const BridgeContext = createContext(bridge);

export const BridgeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <BridgeContext.Provider value={bridge}>{children}</BridgeContext.Provider>
  );
};

export const useBridge = () => {
  return useContext(BridgeContext);
};

import { basicUIPlugin } from "@stackflow/plugin-basic-ui";
import { historySyncPlugin } from "@stackflow/plugin-history-sync";
import { preloadPlugin } from "@stackflow/plugin-preload";
import { basicRendererPlugin } from "@stackflow/plugin-renderer-basic";
import { stackflow } from "@stackflow/react";
import React from "react";

import { preloadDataMap } from "./lib/readPreloadData";

// rome-ignore lint/suspicious/noExplicitAny: intentional
declare const window: any;

const activities = {
  Entry: React.lazy(() => import("./activities/Entry")),
  DealFlow: React.lazy(() => import("./activities/DealFlow")),
  KarrotLife: React.lazy(() => import("./activities/KarrotLife")),
  Safety: React.lazy(() => import("./activities/Safety")),
};

const theme =
  typeof window !== "undefined" &&
  /iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase())
    ? "cupertino"
    : "android";

export const { Stack } = stackflow({
  transitionDuration: 350,
  activities,
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme,
    }),
    historySyncPlugin({
      routes: {
        Entry: "/",
        DealFlow: "/deal-flow",
        KarrotLife: "/karrot-life",
        Safety: "/safety",
      },
      fallbackActivity: () => "Entry",
    }),
    preloadPlugin({
      loaders: {
        Entry({
          activityParams,
          isInitialActivity,
          initialContext,
          activityContext,
        }) {
          const key = `Entry#${JSON.stringify(activityParams)}` as string;

          if (isInitialActivity) {
            preloadDataMap[key] = {
              _t: "ok",
              // rome-ignore lint/suspicious/noExplicitAny: intentional
              data: (initialContext as any).data,
            };
          }

          if (!preloadDataMap[key]) {
            const promise = window.___loader
              // rome-ignore lint/suspicious/noExplicitAny: intentional
              .loadPage((activityContext as any).path)
              .then(
                // rome-ignore lint/suspicious/noExplicitAny: intentional
                (result: any) =>
                  (preloadDataMap[key] = {
                    _t: "ok",
                    data: result.json.data,
                  }),
              );

            preloadDataMap[key] = {
              _t: "pending",
              promise,
            };
          }

          return {
            key,
          };
        },
        DealFlow({
          activityParams,
          isInitialActivity,
          initialContext,
          activityContext,
        }) {
          const key = `DealFlow#${JSON.stringify(activityParams)}` as string;

          if (isInitialActivity) {
            preloadDataMap[key] = {
              _t: "ok",
              // rome-ignore lint/suspicious/noExplicitAny: intentional
              data: (initialContext as any).data,
            };
          }

          if (!preloadDataMap[key]) {
            const promise = window.___loader
              // rome-ignore lint/suspicious/noExplicitAny: intentional
              .loadPage((activityContext as any).path)
              .then(
                // rome-ignore lint/suspicious/noExplicitAny: intentional
                (result: any) =>
                  (preloadDataMap[key] = {
                    _t: "ok",
                    data: result.json.data,
                  }),
              );

            preloadDataMap[key] = {
              _t: "pending",
              promise,
            };
          }

          return {
            key,
          };
        },
        Safety({
          activityParams,
          isInitialActivity,
          initialContext,
          activityContext,
        }) {
          const key = `Safety#${JSON.stringify(activityParams)}` as string;

          if (isInitialActivity) {
            preloadDataMap[key] = {
              _t: "ok",
              // rome-ignore lint/suspicious/noExplicitAny: intentional
              data: (initialContext as any).data,
            };
          }

          if (!preloadDataMap[key]) {
            const promise = window.___loader
              // rome-ignore lint/suspicious/noExplicitAny: intentional
              .loadPage((activityContext as any).path)
              .then(
                // rome-ignore lint/suspicious/noExplicitAny: intentional
                (result: any) =>
                  (preloadDataMap[key] = {
                    _t: "ok",
                    data: result.json.data,
                  }),
              );

            preloadDataMap[key] = {
              _t: "pending",
              promise,
            };
          }

          return {
            key,
          };
        },
        KarrotLife({
          activityParams,
          isInitialActivity,
          initialContext,
          activityContext,
        }) {
          const key = `KarrotLife#${JSON.stringify(activityParams)}`;

          if (isInitialActivity) {
            preloadDataMap[key] = {
              _t: "ok",
              // rome-ignore lint/suspicious/noExplicitAny: intentional
              data: (initialContext as any).data,
            };
          }

          if (!preloadDataMap[key]) {
            const promise = window.___loader
              // rome-ignore lint/suspicious/noExplicitAny: intentional
              .loadPage((activityContext as any).path)
              .then(
                // rome-ignore lint/suspicious/noExplicitAny: intentional
                (result: any) =>
                  (preloadDataMap[key] = {
                    _t: "ok",
                    data: result.json.data,
                  }),
              );

            preloadDataMap[key] = {
              _t: "pending",
              promise,
            };
          }

          return {
            key,
          };
        },
      },
    }),
  ],
});

export type TypeActivities = typeof activities;

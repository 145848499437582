module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-next-seo-virtual-404b1eeb2b/0/cache/gatsby-plugin-next-seo-npm-1.10.0-e59979a254-ed02f47b12.zip/node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-prismic-previews-virtual-31d3e46a6e/0/cache/gatsby-plugin-prismic-previews-npm-5.2.8-47158bcfa3-9e48e0750a.zip/node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"karrot","accessToken":"MC5ZczU4QUJBQUFDRUFtZXBS.77-977-977-9Ye-_vUjvv70377-9ZSzvv73vv70o77-977-977-977-977-977-977-977-977-9H--_vVDvv71477-9B1An","promptForAccessToken":true,"apiEndpoint":"https://karrot.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-datadog-rum-npm-1.0.1-3531b0ec46-c03c16ef3c.zip/node_modules/gatsby-plugin-datadog-rum/gatsby-browser.js'),
      options: {"plugins":[],"applicationId":"8c534644-b700-4d94-9e51-da5cbc4a127b","clientToken":"pube94983f52f73d24f3400058826f24d0f","site":"datadoghq.com","service":"fleamarket-japan-novice-guide-webview","sessionSampleRate":100,"enabled":true,"trackInteractions":true,"trackResources":true,"allowedTracingOrigins":["https://jp-novice-guide.karrotwebview.com","https://jp-novice-guide.alpha.karrotwebview.com"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-82c7a0c9a8/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import "./src/styles/global.css.ts";
import "@seed-design/stylesheet/global.css";
import "@stackflow/plugin-basic-ui/index.css";
import "react-lazy-load-image-component/src/effects/opacity.css";

import { WrapPageElementBrowserArgs } from "gatsby";
import React from "react";

import { AppScreenThemeProvider } from "./src/AppScreenThemeContext";
import { BridgeProvider } from "./src/bridge";
import { Stack } from "./src/stackflow";

export const wrapPageElement = ({
  props: { location, data },
}: WrapPageElementBrowserArgs) => {
  return (
    <React.StrictMode>
      <BridgeProvider>
        <AppScreenThemeProvider>
          <React.Suspense>
            <Stack
              initialContext={{ req: { path: location.pathname }, data }}
            />
          </React.Suspense>
        </AppScreenThemeProvider>
      </BridgeProvider>
    </React.StrictMode>
  );
};
